// scss variables
@import "./variables";

.messageContainer {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  width: 100%;
  max-width: calc(390px - 40px);
}

.messageLeft {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.messageRight {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1 0 0;
  justify-content: center;
  height: 100%;
  overflow-wrap: anywhere;
}

.messageBubble {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
}

.userText {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: $subtext-grey;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  white-space: pre-line;
}

.botText {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  color: black;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  white-space: pre-line;
}

.avatarImg {
  height: 24px;
  width: 24px;
}

@media only screen and (min-width: 600px) {
  .messageContainer {
    max-width: none;
    gap: 20px;
  }

  .userText {
    font-size: $mobile-body-size;
    line-height: $mobile-body-line-height;
  }

  .botText {
    font-size: $mobile-body-size;
    line-height: $mobile-body-line-height;
  }

  .avatarImg {
    width: $mobile-body-line-height;
    height: $mobile-body-line-height;
  }
}
