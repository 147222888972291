// backgrounds
$global-background: white;
$card-background: #04293a;
$snackbar-background: #96e6b3;
$button-background: #ecb365;

// colors
$subtext-grey: #878787;
$border-grey: #e7e7e7;
$error: #fb2576;
$twitter: #1da1f2;
$light-green: #96e6b3;

// text
$primary-text-color: black;
$secondary-text-color: #041c32;

// fonts
$title-font: "Inter", sans-serif;
$title-size: 20px;
$body-font: "Inter", sans-serif;
$body-size: 16px;
$mobile-body-size: 36px;
$mobile-body-line-height: 50px;

// sizing
$side-margin: 4%;
$navbar-height: 80px;
