// scss variables
@import "./variables";

.inputContainer {
  display: flex;
  width: calc(100% - 20px);
  padding: 10px 10px 10px 20px;
  align-items: stretch;
  border-radius: 20px;
  background: #f3f3f3;
}

.inputRow {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}

.inputField {
  font-size: 16px;
  font-family: $body-font;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 137.5% */
  flex: 1 0 0;
  border: none;
  background: transparent;
  min-height: 24px;
}

.inputField:focus {
  outline: none;
  box-shadow: none; /* This can help in some browsers */
}

.submitContainer {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.submitIcon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 120;
}

@media only screen and (min-width: 768px) {
  .inputContainer {
    padding: 30px 20px 30px 30px;
    border-radius: 50px;
  }

  .inputField {
    font-size: $mobile-body-size;
    line-height: $mobile-body-line-height;
    min-height: $mobile-body-line-height;
  }

  .submitIcon {
    width: 80px;
    height: 80px;
  }
}
