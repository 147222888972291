@keyframes reveal {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.letter {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  animation: reveal 0.5s linear forwards;
}
