// scss variables
@import "./variables";

.caContainer {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  position: relative;
}

.messagingContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  flex: 1 0 0;
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.messagingContainer::-webkit-scrollbar {
  display: none;
}

@media only screen and (min-width: 600px) {
  .caContainer {
    padding: 40px;
  }

  .messagingContainer {
    gap: 40px;
  }
}
