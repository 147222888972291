@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
// scss variables
@import "./variables";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $global-background;
  font-family: $body-font;
  color: $primary-text-color;
  font-size: $body-size;
}

p {
  padding: 0;
  margin: 0;
}

li p {
  padding: 0;
  margin: 0;
  display: list-item;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #3019b9;
}

ul,
li {
  display: flex;
  padding: 0;
  margin: 0;
}

textarea {
  resize: none;
}

ol {
  padding-inline-start: 20px;
  margin: 0;
}
